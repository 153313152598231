<template>
    
    <div v-on:focusout="slideToggleNotification($event)" tabindex="0">
        <loader :is-visible="isLoading"></loader>
        <input type="hidden" id="hdnTotalUnreadMessageCount" :value="notifications.TotalUnreadMessageCount" />
        <div v-if="notifications.ReqestFrom== 'count' && notifications.TotalUnreadMessageCount <= 0">
            <div class="w-100 pl-2 py-0 border-bottom theme-primary text-white">
                <span>Notification</span>
                <span class="float-right">
                    <a href="javascript:;" id="anccrossicon" class="crossicon text-white mt-2" @click="slideToggleNotification($event)"><i class="fa fa-times"></i></a>
                </span>
            </div>
            <div class="row">
                <div class="col-12 text-center text-danger">{{$t('NoRecordfound')}}</div>
            </div>
            <div class="text-center border-top" style="line-height:38px;">
                <a href="javascript:;" title="" id="seeAll" @click="openViewNotifactionDetail()">{{$t('ViewAll')}}</a>
            </div>
        </div>
        <div v-else class="rows">
            <input type="hidden" id="hdnStartDate" value="1/1/0001 12:00:00 AM" />
            <input type="hidden" id="hdnLastDate" value="1/1/0001 12:00:00 AM" />
    
            <div class="w-100 pl-2 py-0 border-bottom theme-primary text-white">
                <span>Notification</span>
                <span class="float-right">
                    <a href="javascript:void(0);" title="" id="AllRead" @click="MarkAsReadUnread('','','AllNotifications')" class=" text-white p-action-btn-noti">Mark All as Read</a>
                    <a href="javascript:void(0);" title="" id="btnClear" @click="getUnReadNotifications()" class=" text-white p-action-btn-noti">Clear All</a>
                </span>
            </div>
    
            <div class="inbox_list" :id="notifications.ReqestFrom== 'count'?'divinbox_listdata':'divinbox_list'">
                <template v-for="(item, itemIndex) in notifications.inbox">
                    <p v-if="notifications.ReqestFrom == 'count'" class="border-bottom header-msg-date mb-0 "><span class=" px-2 py-1 text-dark">
                            <!-- {{ $options.filters.formatDate(item.Date) }} -->
                            {{item.Date.split(' ')[0]}}
                        </span></p>
                    <p v-else :class="{ 'border-bottom': ture, 'mt-4': itemIndex > 1 }">
                        <!-- {{ $options.filters.formatDate(item.Date) }} -->
                        {{item.Date.split(' ')[0]}}
                    </p>
                    <div v-for="(inbox, inboxIndex) in item.Items" :class="{ 'list_rw unread': true, 'read': inbox.IsRead, 'unread': !inbox.IsRead }">
                        <div class="icon colored"><i :class="getNotificationClass(inbox.Type)"></i></div>
                        <div class="detail">
                            <p v-if="!inbox.IsRead" :class="{ 'read': inbox.IsRead, 'unread': !inbox.IsRead }">
                                <a v-if='inbox.Type == "JOB_APPLY" || inbox.Type == "INTERVIEW_ACCEPTED" || inbox.Type == "INTERVIEW_REJECTED" || inbox.Type == "INTERVIEW_RESCHEDULE_REQUESTED"' href="javascript:;" class="pop" :id="inbox.Id" :type="inbox.Type" @click="openNotifactionDetail(inbox,$event)">{{ inbox.Message }}</a>
                                <a v-else href="javascript:;" @click="openNotifactionDetail(inbox,$event)" class="pop" :id="inbox.Id" :type="inbox.Type">{{ inbox.Title }} By {{ inbox.PostedBy }}</a>
                                <input type="hidden" Name="value" :value="inbox.Message" />
                            </p>
                            <p v-else>
                                <a @click="openNotifactionDetail(inbox,$event)" href="javascript:void(0);" class="pop" :id="inbox.Id" :type="inbox.Type">{{ inbox.Title }} By {{ inbox.PostedBy }}</a>
                                <input type="hidden" Name="value" :value="inbox.Message" />
                            </p>
                            <p class="timer" v-if="inbox.PostedDate != null">{{ formatTimeIn12Hour(inbox.PostedDate) }}</p> <!-- {{ $options.filters.formatTime(inbox.PostedDate) }} -->
                        </div>
                        <a href="javascript:;" :class="{ 'inboxarrowdowncnt': notifications.ReqestFrom == 'count', 'inboxarrowdownunr': notifications.ReqestFrom != 'count' }" data-placement="left">
                            <i class="fa fa-angle-down"></i>
                        </a>
                        <div :class="{ 'list-rw-detail d-none': true, 'pl-0 pt-0 list-rw-detailline': notifications.ReqestFrom == 'count'  }">
                            {{ inbox.Message }}
                        </div>
                    </div>
                </template>
                <div style="font-size:79%;text-align: center;" v-if="notifications.inbox.length==0"><span class="text-danger text-center no-record">No record(s) found</span></div>
            </div>
            <div class="text-center border-top" style="line-height:38px;">
                <a href="javascript:;" title="" id="seeAll" @click="openViewNotifactionDetail()">View all</a>
            </div>
    
        </div>
        <ViewAllNotification v-if="ViewAllNotification" v-on:closenotification="ViewAllNotification=false"></ViewAllNotification>
        <NotificationDetail v-if="showNotificationpopup" :id="id" :type="type" @close="closeEventViewModal"></NotificationDetail>
    </div>
    </template>
    
    <script>
    import DataService from '../../services/DataService';
    import {   storeToRefs } from "pinia";
    import NotificationDetail from '../Notification/NotificationDetail.vue'
    import ViewAllNotification from "../Notification/ViewAllNotification.vue";
    
    export default {
        components: {
            storeToRefs,
            ViewAllNotification,
            NotificationDetail,
    
        },
        props: {
            notifications: null,
    
        },
    
        data() {
            return {
                showNotificationpopup: false,
                ViewAllNotification: false,
                id: 0,
                type: '',
                moduleId:0,
                inboxList:[],
                keyword:null,
                totalRecords:0,
                fromDate: null,
                toDate: null,
                isLoading:false
            }
        },
        created() {
            console.log(this.notifications)
            if (this.notifications.TotalUnreadMessageCount) {
                var preFixClass = "header-menu-for-left";
                if($('.header-menu-for-left').hasClass('d-none')){
                    preFixClass = 'header-menu-for-top';
                }
                $("." + preFixClass + " #popNotificationCount").html((this.notifications.TotalUnreadMessageCount >= 100)? "99+": this.notifications.TotalUnreadMessageCount ).removeClass("d-none");
            }
    
        },
        // updated(){
        //     this.$refs.menuOptions.focus();
        // },
        methods: {
           
            getNotificationClass(notificationtype) {
                var css = "newmessage"
                switch (notificationtype) {
    
                    case "Comment":
                        css = "fa fa-comments";
                        break;
                    case "CRM":
                        css = "fa fa-user";
                        break;
                    case "PRJASSIGN":
                        css = "fa fa-folder-open";
                        break;
                    case "TSKASSIGN":
                        css = "fa fa-tasks";
                        break;
                    case "EXPAPPR":
                        css = "fa fa-money";
                        break;
                    case "EXPREJ":
                        css = "fa fa-times-circle-o";
                        break;
                    case "EXPSENTFORCORREC":
                        css = "fa fa-usd";
                        break;
                    case "TIMEAPPR":
                        css = "fa fa-clock-o";
                        break;
                    case "TIMEREJ":
                        css = "fa fa-history";
                        break;
                    case "LEAVEAPPLY":
                        css = "fa fa-user-times";
                        break;
                    case "LEAVEUPDATE":
                        css = "fa fa-check-square-o";
                        break;
                    case "LEAVEAPPR":
                        css = "fa fa-user-circle";
                        break;
                    case "LEAVEREJ":
                        css = "fa fa-times-circle-o";
                        break;
                    case "LEAVEAPPLY_APPROVER":
                        css = "fa fa-user-times";
                        break;
                    case "LEAVEUPDATE_APPROVER":
                        css = "fa fa-check-square-o";
                        break;
                    case "TICKET_CREATED":
                        css = "fa fa-ticket";
                        break;
                    case "TICKET_DELETED":
                        css = "fa fa-trash";
                        break;
                    case "TICKET_REPLY":
                        css = "fa fa-reply";
                        break;
                    case "TICKET_TRANSFER":
                        css = "fa fa-code-fork";
                        break;
                    case "TICKET_STATUS_CHANGE":
                        css = "fa fa-exclamation-circle";
                        break;
                    case "TOTAL_FLAGGED_RECORDS":
                        css = "fa fa-flag";
                        break;
                    default:
                        css = "fa fa-bullhorn";
                        break;
                }
                return css;
            },
            openNotifactionDetail: function (inbox, event) {
                if (!inbox.IsRead) {
                    $(event.target).parent().removeClass('unread');
                    $(event.target).addClass('text-dark');
                    var count = parseInt($('#aUnread').html(), 10) - 1;
                    var preFixClass = "header-menu-for-left";
                    if($('.header-menu-for-left').hasClass('d-none')){
                        preFixClass = 'header-menu-for-top';
                    }
                    
                    if ($("." + preFixClass + " #popNotificationCount").length > 0) {
                        count = parseInt(($("." + preFixClass + " #popNotificationCount").html()).replace("+"), 10) - 1;
                    }
                    if (count > 0) {
                        if ($("." + preFixClass + " #popNotificationCount").length > 0) {
                            $("." + preFixClass + " #popNotificationCount").html(count >= 100 ? "99+": count);
                        }
                        if ($('#aUnread').length > 0) {
                            $('#aUnread').html(count >= 100 ? "99+": count);
                        }
                    } else {
                        if ($('#aUnread').length > 0) {
                            $('#aUnread').remove();
                        }
                        if ($("." + preFixClass + " #popNotificationCount").length > 0) {
                            $("." + preFixClass + " #popNotificationCount").hide();
                        }
                    }
                }
                this.id = inbox.Id;
                this.type = inbox.Type;
                this.showNotificationpopup = true;
            },
            openViewNotifactionDetail: function () {
                this.ViewAllNotification = true;
                const customEvent = new CustomEvent('event_viewnotification')
                window.dispatchEvent(customEvent);    
    
            },
            closeEventViewModal: function () {
                this.showNotificationpopup = false;
            },
    
            async MarkAsReadUnread(id, action, reqFrom) {
                var vm = this;
                vm.isLoading = true;
                var url = `id=${id}&action=${action}&reqFrom=${reqFrom}`;
                await DataService.UnreadAllItems(url).then(response => {
                    if (response.data == true) {              
                       vm.getUnReadNotifications();
                    } else {
                        vm.isLoading = false;
                    }
                })
            },
            getUnReadNotifications() {
                 var vm = this;
                 DataService.UnreadItems("reqFrom=count").then(function (response) {
                     vm.notifications = response.data;
                     vm.isLoading = false;
                 });
            },
            formatTimeIn12Hour(date) {
                date = new Date(date);
                const hours24 = date.getHours();
                const minutes = date.getMinutes();
                const seconds = date.getSeconds();
                
                let hours12 = hours24 % 12;
                hours12 = hours12 ? hours12 : 12; // the hour '0' should be '12'
                const ampm = hours24 >= 12 ? 'PM' : 'AM';
                
                // Pad the minutes and seconds with leading zeros if necessary
                const minutesPadded = minutes < 10 ? '0' + minutes : minutes;
                const secondsPadded = seconds < 10 ? '0' + seconds : seconds;
                
                //return `${hours12}:${minutesPadded}:${secondsPadded} ${ampm}`;
                return `${hours12}:${minutesPadded} ${ampm}`;
            }
                    
        }
    }
    </script>
    
    <style scoped>
    .list-rw-detailline {
        line-height: 25px;
    }
    
    .p-action-btn-noti {
        padding: 0px 10px;
        min-width: 65px;
        float: left;
        text-align: center;
        font-size: 13px;
    }
    
    #divPopNotificationData.themecontrol {
        background: #fff;
        width: 450px;
        max-width: 450px;
        z-index: 99;
        right: 110px;
    }
    </style>
    